import React, { useState, useRef } from 'react';
import { StatusBar, TextInput, View, Text, ScrollView, Pressable, Alert, Image, Dimensions, FlatList } from 'react-native';
import { colors } from '../common/color';
import { CustomConsole, CustomProgressBar, FooterComponent, getDMSansBoldFont, getDMSansMediumFont, getDMSansRegularFont, HeaderComponent, scrollToTop, validateEmail } from '../common/utils';
import { CARTLIST, LOGIN, PRODUCT } from '../common/webUtils';
import { useIsFocused } from '@react-navigation/native';
import { getSession, USER_ID } from '../common/LocalStorage';
import { Picker } from '@react-native-picker/picker';
import { styles, ids } from '../common/responsive.js';

function ProductListScreen({ navigation }) {

    const scrollRef = useRef();

    const onPressTouch = () => {
        scrollRef.current?.scrollTo({
            y: 0,
            animated: true,
        });
    }

    const [loading, setLoading] = useState(false);
    const [productList, setProductList] = useState([]);
    const [userId, setUserId] = useState('');
    const [selectSize, setSelectSize] = useState([]);
    const [cartLength, setcartLength] = React.useState('0');

    //for check screen focused
    const isFocused = useIsFocused();

    //useeffect
    React.useEffect(() => {
        getSessionData();
        scrollToTop();
    }, [isFocused]);

    // get sessiondata
    const getSessionData = async () => {
        try {
            const user_id = await getSession(USER_ID);
            setUserId(user_id);
            productListApi();
            cartListApi();
        } catch (error) {

        }
    }

    // cart list api
    const cartListApi = async () => {
        try {
            const user_id = await getSession(USER_ID);
            var raw = JSON.stringify({
                "customer_id": user_id
            });

            var requestOptions = {
                method: 'POST',
                body: raw,
            };

            CustomConsole(CARTLIST);

            // setLoading(true);
            fetch(CARTLIST, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    setcartLength(json.data.cart.length);
                    CustomConsole("Cart length" + cartLength)
                    // setLoading(false);
                })
                .catch((error) => {
                    // setLoading(false);
                    CustomConsole("Cart Api Error=>" + error);
                });
        } catch (e) {
            CustomConsole("Cart Exception=>" + e + "");
        }
    }

    // product list api
    const productListApi = async () => {
        try {
            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            CustomConsole(PRODUCT);

            setLoading(true);
            fetch(PRODUCT, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    productList.length = 0;
                    for (let i = 0; i < json.data.length; i++) {

                        let sizes_arr = [];

                        if (json.data[i].options.length != 0) {
                            for (let j = 0; j < json.data[i].options[0].product_option_value.length; j++) {
                                sizes_arr.push({
                                    "product_option_value_id": json.data[i].options[0].product_option_value[j].product_option_value_id,
                                    "option_value_id": json.data[i].options[0].product_option_value[j].option_value_id,
                                    "name": json.data[i].options[0].product_option_value[j].name,
                                    "image": json.data[i].options[0].product_option_value[j].image,
                                    "quantity": json.data[i].options[0].product_option_value[j].quantity,
                                    "subtract": json.data[i].options[0].product_option_value[j].subtract,
                                    "price": json.data[i].options[0].product_option_value[j].price,
                                    "price_prefix": json.data[i].options[0].product_option_value[j].price_prefix,
                                    "weight": json.data[i].options[0].product_option_value[j].weight,
                                    "weight_prefix": json.data[i].options[0].product_option_value[j].weight_prefix,
                                    "product_option_id": json.data[i].options[0].product_option_id
                                });
                            }
                            let selectedSize = selectSize;
                            selectedSize[i] = {
                                product_option_value_id: json.data[i].options[0].product_option_value[0].product_option_value_id,
                                name: json.data[i].options[0].product_option_value[0].name,
                                product_option_id: json.data[i].options[0].product_option_id,
                                price: json.data[i].options[0].product_option_value[0].price,
                            }
                            setSelectSize(selectedSize);
                        } else {
                            CustomConsole("Index" + i);
                            let selectedSize = selectSize;
                            selectedSize[i] = {
                                product_option_value_id: '',
                                name: '',
                                product_option_id: '',
                                price: '0'
                            }
                            setSelectSize(selectedSize);
                        }

                        if (json.data[i].is_wallet != 1) {
                            productList.push({
                                index: i,
                                product_id: json.data[i].product_id,
                                name: json.data[i].name,
                                is_wallet: json.data[i].is_wallet == undefined ? 0 : json.data[i].is_wallet,
                                description: json.data[i].description,
                                is_wallet: json.data[i].is_wallet,
                                price: json.data[i].price.substring(1).replace(",", ""),
                                currencySymbol: json.data[i].price.substring(0, 1),
                                image: json.data[i].image != "https://dairyfarm.alakmalak.ca/image/" ? { uri: json.data[i].image } : require("../assets/header/footer-logo.png"),
                                // image: json.data[i].image != "https://dairyfarm.alakmalak.ca/image/" ? { uri: "https://design.hire-webdeveloper.com/smooth-oraganic/v2/images/product-list-img-1.png" } : require("../assets/header/footer-logo.png"),
                                weight: parseFloat(json.data[i].weight).toFixed(2),
                                rating: Math.floor(parseFloat(json.data[i].rating)),
                                sizes: sizes_arr,
                                stock_status: json.data[i].stock_status
                            });
                        }
                    }

                    CustomConsole(productList);
                    setProductList(productList);
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("Product Api Error=>" + error);
                });
        } catch (e) {
            setLoading(false);
            CustomConsole("Product Exception=>" + e + "");
        }
    }

    // product item view
    const productItem = (item, index) => {
        // CustomConsole((parseFloat(item.price)))
        return (
            <View style={styles.productScreenMainView} dataSet={{ media: ids.productScreenMainView }}>
                <Pressable style={styles.productScreenLogoContainer} dataSet={{ media: ids.productScreenLogoContainer }} onPress={() => navigation.navigate("ProductDetailsScreen", { paramId: item.product_id, paramName: item.name, isWallet: item.is_wallet })}>
                    <View style={styles.productScreenLogoWrapper} />
                    <Image source={item.image} style={item.image != require("../assets/header/footer-logo.png") ? styles.itemCardRealImage : styles.itemsCardDummyImage} />
                </Pressable>

                <View style={styles.productScreenItemListView}>
                    <Text style={styles.listItemTitle} numberOfLines={1} dataSet={{ media: ids.listItemTitle }}>{item.name}</Text>
                    {item.is_wallet == 1 ? null :
                        <>
                            <View style={styles.listItemContainer} dataSet={{ media: ids.listItemContainer }}>
                                <Image source={require("../assets/header/star.png")}
                                    style={item.rating >= 1 ? styles.itemReviewActiveStar : styles.itemReviewInactiveStar} />
                                <Image source={require("../assets/header/star.png")}
                                    style={item.rating >= 2 ? styles.itemReviewActiveStar : styles.itemReviewInactiveStar} />
                                <Image source={require("../assets/header/star.png")}
                                    style={item.rating >= 3 ? styles.itemReviewActiveStar : styles.itemReviewInactiveStar} />
                                <Image source={require("../assets/header/star.png")}
                                    style={item.rating >= 4 ? styles.itemReviewActiveStar : styles.itemReviewInactiveStar} />
                                <Image source={require("../assets/header/star.png")}
                                    style={item.rating >= 5 ? styles.itemReviewActiveStar : styles.itemReviewInactiveStar} />
                            </View>

                            <View >
                            <Text style={item.stock_status != "Out Of Stock" ? styles.productListStockStatusInstockText : styles.productListStockStatusOutstockText}>{item.stock_status}</Text>
                            </View>
                            <View style={styles.itemsCardView} dataSet={{ media: ids.itemsCardView }}>
                                {item.sizes.length != 0 ? <Picker
                                    style={styles.itemsCardPicker} dataSet={{ media: ids.itemsCardPicker }}
                                    selectedValue={selectSize[index].product_option_value_id}
                                    onValueChange={(itemValue, itemIndex) => {
                                        let tempSelectedSize = [...selectSize];
                                        tempSelectedSize[index] = {
                                            product_option_value_id: itemValue,
                                            name: item.sizes[itemIndex].name,
                                            product_option_id: item.sizes[itemIndex].product_option_id,
                                            price: item.sizes[itemIndex].price,
                                        };
                                        setSelectSize(tempSelectedSize);
                                    }}>
                                    {item.sizes.map((item1, index1) => {
                                        return (<Picker.Item label={item1.name} value={item1.product_option_value_id} />);
                                    })}
                                </Picker> : null}
                                <Text style={styles.itemsCardLines} numberOfLines={1}>{item.currencySymbol}{(parseFloat(item.price) + parseFloat(selectSize[index].price)).toFixed(2)}</Text>
                            </View>

                            {item.stock_status != "Out Of Stock" ? <Pressable onPress={() => {
                                if (userId == null || userId == "") {
                                    CustomConsole(window.location.href)
                                    navigation.navigate("RegisterScreen", { paramScreenRoute: window.location.href });
                                } else {
                                    // CustomConsole(selectSize);
                                    // CustomConsole(selectSize[index]);
                                    navigation.navigate("CheckoutScreen", {
                                        paramProductName: item.name,
                                        paramProductId: item.product_id,
                                        paramProductSize: selectSize[index].name,
                                        paramProductPrice: item.currencySymbol + (parseFloat(item.price) + parseFloat(selectSize[index].price)).toFixed(),
                                        paramProductImage: item.image,
                                        paramProductOptionId: selectSize[index].product_option_id,
                                        paramProductOptionValueId: selectSize[index].product_option_value_id,
                                    });
                                }
                            }}
                                style={styles.itemsCardCartButton}>
                                <Text style={styles.itemsCardCartText} dataSet={{ media: ids.itemsCardCartText }}>ADD TO CART</Text>
                            </Pressable> : null}
                        </>
                    }
                </View>
            </View>
        );
    }

    return (
        <View>
            <StatusBar barStyle="dark-content" />
            <ScrollView ref={scrollRef} style={styles.container} keyboardShouldPersistTaps='handled'>
                <View style={styles.mainProductListContainer}>

                    {/* progress view */}
                    {loading ? CustomProgressBar(loading) : null}
                    {/* end of progress view */}

                    {/* Header view */}
                    {HeaderComponent(cartLength, navigation, userId)}
                    {/* end of Header view */}

                    {/* our product text view */}
                    <View style={styles.mainProductTextView}>
                        <Text style={styles.mainProductText1}>Our Products</Text>
                        <Text style={styles.mainProductText2} dataSet={{ media: ids.mainProductText2 }}>Our flagship product, fresh milk, is the epitome of wholesome goodness. Straight from the udders to your table, it's packed with essential nutrients like calcium, protein, and vitamins, making it a cornerstone of a healthy diet.</Text>
                    </View>
                    {/* end of our product text view */}

                    <View style={styles.mainProductFlatList} dataSet={{ media: ids.mainProductFlatList }}>

                        {productList.length != 0 ? <>
                            {/* product list view */}
                            < FlatList
                                numColumns={(Dimensions.get("window").width > 767) ? 3 : 1}
                                showsVerticalScrollIndicator={false}
                                data={productList}
                                keyExtractor={(item, id) => String(id)}
                                renderItem={({ item, index }) => productItem(item, index)} />
                        </>
                            :
                            !loading ?
                                <View style={styles.mainProductLoadingView}>
                                    <Text style={styles.mainProductLoadingText}>Product is not available</Text>
                                </View> :
                                <View style={styles.loadingTextBottomView}>
                                </View>
                        }

                    </View>

                    {/* Footer view */}
                    {/* {FooterComponent(navigation, userId)}*/}
                    {/* end of Footer view */}

                </View>
            </ScrollView>
        </View>
    );
};

export default ProductListScreen;
